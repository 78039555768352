import React from "react";
import { Routes, Route } from "react-router-dom";
import OrderPage from "../pages/Order";
import ConfigPackages from "../pages/configuration/ConfigPackages";
import ConfigDeliveryMethods from "../pages/configuration/ConfigDeliveryMethods";
import ConfigOtherControls from "../pages/configuration/ConfigOtherControls";
import ConfigSendValidations from "../pages/configuration/ConfigSendValidations";
import ConfigServers from "../pages/configuration/ConfigServers";
import ConfigResponseMessages from "../pages/configuration/ConfigResponseMessages";
import ConfigBundling from "../pages/configuration/configBundling/ConfigBundling";
import ConfigMisc from "../pages/configuration/ConfigMisc";
import ConfigPushback from "../pages/configuration/ConfigPushback";
import LandingPage from "../pages/landing"; 

const NavPage : React.FC = () => {

    const configRoutes = [
        { path: 'ConfigServers', el: ConfigServers },
        { path: 'ConfigPackages', el: ConfigPackages },
        { path: 'ConfigDeliveryMethods', el: ConfigDeliveryMethods },
        { path: 'ConfigOtherControls', el: ConfigOtherControls },
        { path: 'ConfigSendValidations', el: ConfigSendValidations },
        { path: 'ConfigResponseMessages', el: ConfigResponseMessages },
        { path: 'ConfigBundling', el: ConfigBundling },
        { path: 'ConfigMisc', el: ConfigMisc },
        { path: 'ConfigPushback', el: ConfigPushback },
    ];

    const configBasePath = "/configuration";
    
    return (
         <>
            <section>
                <Routes>
                    <Route
                        path="/"
                        element={<LandingPage />}
                    /> 
                    <Route 
                        path="/Order"
                        element={<OrderPage />} /> 
                    {configRoutes.map((obj,idx) => (
                        <Route key={idx}
                            path={`${configBasePath}/${obj.path}`} 
                            element={< obj.el />} />
                    ))}; 
                </Routes>       
            </section> 
         </>
    );
};

export default NavPage;