import axios from 'axios';

class ConformXService {
    private baseURL: string;

    constructor(baseURL: string) {
        this.baseURL = baseURL;
    }

    public async isAuthenticated(username: string, password: string): Promise<boolean> {
        try {
            const soapRequest = `<?xml version="1.0" encoding="utf-8"?>
<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
  <soap12:Body>
    <IsAuthenticated xmlns="http://conformx.docutechcorp.com/ConformXWS">
      <username>${username}</username>
      <password>${password}</password>
    </IsAuthenticated>
  </soap12:Body>
</soap12:Envelope>`

            const response = await axios.post(`${this.baseURL}`, soapRequest, {
                headers: {
                    'Content-Type': 'application/soap+xml; charset=utf-8',
                },
            });

            // Check if the response contains a successful result
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(response.data, "text/xml");
            const successNode = xmlDoc.getElementsByTagName("Success")[0];

            if (successNode && successNode.textContent === "true") {
                return true;
            }
            return false;
        } catch (error) {
            console.error("ConformXService IsAuthenticated Error: ", error);
            return false;
        }
    }
}

export default ConformXService;