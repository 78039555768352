export const ALL_USERS_ROLE = "-All Users-";

export const REQUEST_TYPES = {
  BOTH: "Both",
  LIGHTS_OUT: "LightsOut",
  LIGHTS_ON: "LightsOn",
  DOCUMENT_REQUEST: "DocumentRequest",
  DOCUMENT_REQUEST_IMPORT: "DocumentRequestImport"
};

export const EPC_REQUEST_TYPES = {
  DISCLOSURES: "Disclosures",
  CLOSING: "Closing_Package"
}

export const DELIVERY_PREFIX = {
  EDISCLOSE: "edisclose",
  ESIGN: "esign",
  ECLOSE: "eclose",
  RON: "ron",
  ENOTE: "enote",
  IPEN: "ipen"
}

export const SAVE_LOAN_OPTIONS = [
  { value: "Always", label: "Always" },
  { value: "WhenRecalculationsAreNotComplete", label: "WhenRecalculationsAreNotComplete" },
  { value: "Never", label: "Never" }
];

export const REQUEST_TYPE_OPTIONS = [
  { value: "LightsOut", label: "LightsOut" },
  { value: "LightsOn", label: "LightsOn" },
  { value: "Both", label: "Both" }
];

export const DEFAULT_REQUEST_OPTIONS = [
  { value: "LightsOut", label: "LightsOut" },
  { value: "LightsOn", label: "LightsOn" }
];

export const PREVIEW_ATTACHMENTS_OPTIONS = [
  { value: "true", label: "True" },
  { value: "false", label: "False" }
];

export const PANEL_IDS = {
  ATTACHMENTS:"pnlAttachments",
  SELECT_TO_PRINT:"pnlSelectToPrint"
}

export const EPC_ACTIONS={
  GET_RESOURCES:"getAvailableResources",
  GET_ROLES:"getRoles"
}
 
export const EMAIL_REGEX = /^\S+@\S+\.\S+$/;