export enum SpecialDeliveryTypes {
	PrintInPerson = "PrintInPerson",
    ESignSecondaryId = "eSignSecondary",
    EDsicloseSecondaryId = "eDiscloseSecondary",
    ECloseSecondaryId = "eCloseSecondary",
}

export enum FullfillmentShippingOptions {
    USPS1stClass = "USPS 1st Class",
    FedExNextDay = "FedEx Next Day",
    USPSPriority = "USPS Priority",
    UPSOvernight = "UPS Overnight", 
}

export enum SpecialClosingTypes {
    RON = "RON",
    RONENote = "RON eNote",
    eNote = "eNote",
    SecondaryRON = "Secondary RON",
    SecondaryRONENote = "Secondary RON eNote",
    SecondaryENote = "Secondary eNote",
    IPEN = "IPEN",
    IPENeNote = "IPENeNote"
}
