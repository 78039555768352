import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import { IOtherControl, IPluginConfiguration } from "../../models/configuration/plugin/IConfiguration";
import EncompassService from "../../services/encompassService";

// This action creator gets the server config values from an api call or state
const saveConfigOtherControlsData = (configId: string, updatedOtherControls: IOtherControl[], token: string | null) => {
    return async (dispatch: Dispatch<Action>, getState: () => any) => {
      
        dispatch({ type: ActionType.SAVE_CONFIG_PLUGIN_DATA });
        
        try {
            
            const { data: pluginConfiguration } = getState().configPlugInData;

            if (!pluginConfiguration) {
                throw new Error(" No PluginConfiguration available in state to save");
            }

            if (!updatedOtherControls || updatedOtherControls.length === 0) {
                throw new Error("No other controls to save");
            }

            // Using the Plug In State Data update the Other Controls
            const updatedConfig = { 
                ...pluginConfiguration.Configuration,
                OtherControls: updatedOtherControls
            }
            
            const requestBody = {
                "ConfigurationId": configId,
                "EncompassInstanceId": EncompassService.getEncompassInstanceId(),
                "Configuration": updatedConfig
            }

            await new PluginService().saveConfig<IPluginConfiguration>(token, "plugin", configId, requestBody);
            
            dispatch({
                type: ActionType.SAVE_CONFIG_PLUGIN_DATA_SUCCESS,
                payload: { "Configuration": updatedConfig }
            });
        
            return Promise.resolve();
            
        } catch (error: any) {
            console.error("Save Other Controls Data");
            const payloadErrorMessage = `Failed to save other controls Data: (${error.message})`;
            dispatch({
                type: ActionType.SAVE_CONFIG_PLUGIN_DATA_FAILURE,
                payload: payloadErrorMessage,
            });
            return Promise.reject(payloadErrorMessage);   
        }
    }
}

export default saveConfigOtherControlsData;