import React, { useState } from "react";
import { TableCell, TextField, Box } from "@mui/material";
import "../../../assets/style.css";

interface DTDataGridTextEditProps {
  text: string;
  cellKey: string;
  alignmentClass: string | undefined;
  dataChangeFunction?: (e: any, item: any) => void;
  rowIndex: number;
  itemName: string;
  editOnClick: boolean | undefined;
  foreColor: string | undefined;
  backColor: string | undefined;
}

const DTDataGridTextEdit: React.FC<DTDataGridTextEditProps> = (
  props: DTDataGridTextEditProps
) => {
  const [inEdit, setInEdit] = useState(false);

  return (
    <>
      {
        <TableCell
          key={props.cellKey}
          className={[props.alignmentClass, "height100"].join(" ")}
          sx={{
            background: props.backColor,
          }}
        >
          {inEdit && props.editOnClick ? (
            <>
              <div key={props.cellKey} className={["height100"].join(" ")}>
                <TextField
                  hiddenLabel
                  name={props.itemName}
                  key={props.cellKey}
                  margin="none"
                  value={props.text ?? ""}
                  fullWidth
                  variant="outlined"
                  onBlur={() => setInEdit(false)}
                  onChange={(e) =>
                    props.dataChangeFunction
                      ? props.dataChangeFunction(e, props.rowIndex)
                      : null
                  }
                  size="small"
                />
              </div>
            </>
          ) : (
            <div
              key={props.cellKey}
              className={[props.alignmentClass, "height100"].join(" ")}
              onClick={() => (props.editOnClick ? setInEdit(!inEdit) : null)}
            >
              <Box
                className="dtcelltrunc"
                sx={{
                  color: props.foreColor,
                }}
              >
                {props.text}
              </Box>
            </div>
          )}
        </TableCell>
      }
    </>
  );
};

export default DTDataGridTextEdit;