import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableRow, TextField } from "@mui/material";

interface DocumentLookupProps {
  open: boolean;
  closeFxtn: () => void;
  documentList: Array<string>;
  itemSelected: (item: string) => void;
}

const DocumentLookup: React.FC<DocumentLookupProps> = ({
  open,
  closeFxtn,
  documentList,
  itemSelected
}) => {
  const [filteredList, setFilteredList] = useState(documentList);
  const [filterText, setFilterText] = useState("");
  const [selectedItem, setSelectedItem] = useState("");

  useEffect(() => {

    if (filterText !== "") {
      setFilteredList(documentList.filter(function (str) { return str.toLowerCase().includes(filterText.toLowerCase()); }));
    }
    else {
      setFilteredList(documentList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText]);

  if (documentList == null) {
    return <></>;
  }

  const saveChanges = () => {
    if (itemSelected != null) {
      itemSelected(selectedItem);
    }
    setFilterText("");
  };

  return (
    <Dialog open={open} onClose={closeFxtn} fullWidth maxWidth="md">
      <DialogTitle>
        {"Encompass Document Lookup"}
        <Box mt={2}>
          <TextField
            label="Search/Filter"
            variant="outlined"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            size="small"
            fullWidth
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{
          minHeight: "60vh",
          maxHeight: "60vh"
        }}>
          <Table size="small">
            <TableBody >
              {filteredList?.map((opt, i) => {
                return (
                  <TableRow
                    key={[i.toString()].join("")}
                    hover
                    onClick={(e) => setSelectedItem(opt)}
                    selected={opt === selectedItem}
                  >
                    <TableCell key={[i.toString()].join("")}>{opt}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
        {/* </Card> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeFxtn}>{"Cancel"}</Button>
        <Button onClick={saveChanges}>{"Save"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentLookup;