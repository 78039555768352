import { IBundleConfiguration,IEventBundleConfiguration } from '../../models/configuration/bundles/IBundles';
import { IDocumentMappingConfiguration } from '../../models/configuration/bundles/IDocumentMappings';
import { IPluginConfiguration } from '../../models/configuration/plugin/IConfiguration';
import { IPushbackConfiguration } from '../../models/configuration/pushback/IConfiguration';
import IIntegration from '../../models/configuration/servers/IIntegration';
import { IPackageTypes } from '../../models/request/IRequest';

export enum ActionType {
    // All Plug In Data
    GET_CONFIG_PLUGIN_DATA = 'get_plugin_data', GET_CONFIG_PLUGIN_DATA_SUCCESS = 'get_plugin_data_success', GET_CONFIG_PLUGIN_DATA_FAILURE = 'get_plugin_data_failure',
    SAVE_CONFIG_PLUGIN_DATA = 'save_plugin_data', SAVE_CONFIG_PLUGIN_DATA_SUCCESS = 'save_plugin_data_success', SAVE_CONFIG_PLUGIN_DATA_FAILURE = 'save_plugin_data_failure',
    // Config Servers
    GET_CONFIG_SERVERS = 'get_config_servers', GET_CONFIG_SERVERS_SUCCESS = 'get_config_servers_success', GET_CONFIG_SERVERS_FAILURE = 'get_config_servers_failure',
    SAVE_CONFIG_SERVERS = 'save_config_servers', SAVE_CONFIG_SERVERS_SUCCESS = 'save_config_servers_success', SAVE_CONFIG_SERVERS_FAILURE = 'save_config_servers_failure',
    // Bundling
    GET_CONFIG_BUNDLE_DATA = 'get_config_bundle_data', GET_CONFIG_BUNDLE_DATA_SUCCESS = 'get_config_bundle_data_success', GET_CONFIG_BUNDLE_DATA_FAILURE = 'get_config_bundle_data_failure',
    SAVE_CONFIG_BUNDLE_DATA = 'save_config_bundle_data', SAVE_CONFIG_BUNDLE_DATA_SUCCESS = 'save_config_bundle_data_success', SAVE_CONFIG_BUNDLE_DATA_FAILURE = 'save_config_bundle_data_failure',
    // Bundling Event
    GET_CONFIG_BUNDLE_EVENT_DATA = 'get_config_bundle_event_data', GET_CONFIG_BUNDLE_EVENT_DATA_SUCCESS = 'get_config_bundle_event_data_success', GET_CONFIG_BUNDLE_EVENT_DATA_FAILURE = 'get_config_bundle_event_data_failure',
    SAVE_CONFIG_BUNDLE_EVENT_DATA = 'save_config_bundle_event_data', SAVE_CONFIG_BUNDLE_EVENT_DATA_SUCCESS = 'save_config_bundle_event_data_success', SAVE_CONFIG_BUNDLE_EVENT_DATA_FAILURE = 'save_config_bundle_event_data_failure',  
     // Document Mapping
    GET_DOCUMENT_MAPPING_DATA = 'get_document_mapping_data', GET_DOCUMENT_MAPPING_DATA_SUCCESS = 'get_document_mapping_data_success', GET_DOCUMENT_MAPPING_DATA_FAILURE = 'get_document_mapping_data_failure',
    SAVE_DOCUMENT_MAPPING_DATA = 'save_document_mapping_data', SAVE_DOCUMENT_MAPPING_DATA_SUCCESS = 'save_document_mapping_data_success', SAVE_DOCUMENT_MAPPING_DATA_FAILURE = 'save_document_mapping_data_failure',    
    // Pushback
    GET_CONFIG_PUSHBACK = 'get_config_pushback', GET_CONFIG_PUSHBACK_SUCCESS = 'get_config_pushback_success', GET_CONFIG_PUSHBACK_FAILURE = 'get_config_pushback_failure',
    SAVE_CONFIG_PUSHBACK = 'save_config_pushback', SAVE_CONFIG_PUSHBACK_SUCCESS = 'save_config_pushback_success', SAVE_CONFIG_PUSHBACK_FAILURE = 'save_config_pushback_failure',
    // Request Package Types
    GET_REQUEST_PACKAGE_TYPES = 'get_request_package_types', GET_REQUEST_PACKAGE_TYPES_SUCCESS = 'get_request_package_types_success', GET_REQUEST_PACKAGE_TYPES_FAILURE = 'get_request_package_types_failure',
}

// Configuration Area --> (Config Servers Screen)
interface getConfigServersAction { type: ActionType.GET_CONFIG_SERVERS; } // This is what is happening during the fetch of the data
interface getConfigServersFailureAction { type: ActionType.GET_CONFIG_SERVERS_FAILURE; payload: string; }
interface getConfigServersSuccessAction { type: ActionType.GET_CONFIG_SERVERS_SUCCESS; payload: IIntegration; }
interface saveConfigServersAction { type: ActionType.SAVE_CONFIG_SERVERS }
interface saveConfigServersFailureAction { type: ActionType.SAVE_CONFIG_SERVERS_FAILURE, payload: string; }
interface saveConfigServersSuccessAction { type: ActionType.SAVE_CONFIG_SERVERS_SUCCESS, payload: IIntegration; }
export type GetConfigActions = getConfigServersAction | getConfigServersSuccessAction | getConfigServersFailureAction;
export type SaveConfigActions = saveConfigServersAction | saveConfigServersSuccessAction | saveConfigServersFailureAction;

// Plug in Data
interface getConfigPluginDataAction { type: ActionType.GET_CONFIG_PLUGIN_DATA; } // This is what is happening during the fetch of the data
interface getConfigPluginDataFailureAction { type: ActionType.GET_CONFIG_PLUGIN_DATA_FAILURE; payload: string; }
interface getConfigPluginDataSuccessAction { type: ActionType.GET_CONFIG_PLUGIN_DATA_SUCCESS; payload: IPluginConfiguration; }
interface saveConfigPluginDataAction { type: ActionType.SAVE_CONFIG_PLUGIN_DATA }
interface saveConfigPluginDataFailureAction { type: ActionType.SAVE_CONFIG_PLUGIN_DATA_FAILURE, payload: string; }
interface saveConfigPluginDataSuccessAction { type: ActionType.SAVE_CONFIG_PLUGIN_DATA_SUCCESS, payload: IPluginConfiguration; }
export type GetPluginActions = getConfigPluginDataAction | getConfigPluginDataFailureAction | getConfigPluginDataSuccessAction;
export type SavePluginActions = saveConfigPluginDataAction | saveConfigPluginDataFailureAction | saveConfigPluginDataSuccessAction;

// Configuration - Document Bundles
interface getConfigBundlesAction { type: ActionType.GET_CONFIG_BUNDLE_DATA; }
interface getConfigBundlesFailureAction { type: ActionType.GET_CONFIG_BUNDLE_DATA_FAILURE; payload: string; }
interface getConfigBundlesSuccessAction { type: ActionType.GET_CONFIG_BUNDLE_DATA_SUCCESS; payload: IBundleConfiguration; }
interface saveConfigBundlesAction { type: ActionType.SAVE_CONFIG_BUNDLE_DATA; }
interface saveConfigBundlesFailureAction { type: ActionType.SAVE_CONFIG_BUNDLE_DATA_FAILURE; payload: string; }
interface saveConfigBundlesSuccessAction { type: ActionType.SAVE_CONFIG_BUNDLE_DATA_SUCCESS; payload: IBundleConfiguration; }
export type GetConfigBundleActions = getConfigBundlesAction | getConfigBundlesFailureAction | getConfigBundlesSuccessAction;
export type SaveConfigBundleActions = saveConfigBundlesAction | saveConfigBundlesFailureAction | saveConfigBundlesSuccessAction;

// Configuration - Document Event Bundles
interface getConfigEventBundlesAction { type: ActionType.GET_CONFIG_BUNDLE_EVENT_DATA; }
interface getConfigEventBundlesSuccessAction { type: ActionType.GET_CONFIG_BUNDLE_EVENT_DATA_SUCCESS; payload: IEventBundleConfiguration; }
interface getConfigEventsBundlesFailureAction { type: ActionType.GET_CONFIG_BUNDLE_EVENT_DATA_FAILURE; payload: string; }
interface saveConfigEventBundlesAction { type: ActionType.SAVE_CONFIG_BUNDLE_EVENT_DATA; }
interface saveConfigEventBundlesSuccessAction { type: ActionType.SAVE_CONFIG_BUNDLE_EVENT_DATA_SUCCESS; payload: IEventBundleConfiguration; }   
interface saveConfigEventBundlesFailureAction { type: ActionType.SAVE_CONFIG_BUNDLE_EVENT_DATA_FAILURE; payload: string; }
export type GetConfigEventBundleActions = getConfigEventBundlesAction | getConfigEventBundlesSuccessAction | getConfigEventsBundlesFailureAction;
export type SaveConfigEventBundleActions = saveConfigEventBundlesAction | saveConfigEventBundlesSuccessAction | saveConfigEventBundlesFailureAction;

// Configuration - Document Event Bundles
interface getDocumentMappingAction { type: ActionType.GET_DOCUMENT_MAPPING_DATA; }
interface getDocumentMappingSuccessAction { type: ActionType.GET_DOCUMENT_MAPPING_DATA_SUCCESS; payload: IDocumentMappingConfiguration; }
interface getDocumentMappingFailureAction { type: ActionType.GET_DOCUMENT_MAPPING_DATA_FAILURE; payload: string; }
interface saveDocumentMappingAction { type: ActionType.SAVE_DOCUMENT_MAPPING_DATA; }
interface saveDocumentMappingSuccessAction { type: ActionType.SAVE_DOCUMENT_MAPPING_DATA_SUCCESS; payload: IDocumentMappingConfiguration; }
interface saveDocumentMappingFailureAction { type: ActionType.SAVE_DOCUMENT_MAPPING_DATA_FAILURE; payload: string; }
export type GetDocumentMappingActions = getDocumentMappingAction | getDocumentMappingSuccessAction | getDocumentMappingFailureAction;
export type SaveDocumentMappingActions = saveDocumentMappingAction | saveDocumentMappingSuccessAction | saveDocumentMappingFailureAction;

// Pushback
interface getConfigPushbackAction { type: ActionType.GET_CONFIG_PUSHBACK; }
interface getConfigPushbackFailureAction { type: ActionType.GET_CONFIG_PUSHBACK_FAILURE; payload: string; }
interface getConfigPushbackSuccessAction { type: ActionType.GET_CONFIG_PUSHBACK_SUCCESS; payload: IPushbackConfiguration; }
interface saveConfigPushbackAction { type: ActionType.SAVE_CONFIG_PUSHBACK; }
interface saveConfigPushbackSuccessAction { type: ActionType.SAVE_CONFIG_PUSHBACK_SUCCESS; payload: IPushbackConfiguration; }
interface saveConfigPushbackFailureAction { type: ActionType.SAVE_CONFIG_PUSHBACK_FAILURE; payload: string; }
export type GetConfigPushbackActions = getConfigPushbackAction | getConfigPushbackFailureAction | getConfigPushbackSuccessAction;
export type SaveConfigPushbackActions = saveConfigPushbackAction | saveConfigPushbackSuccessAction | saveConfigPushbackFailureAction;

// Request Package Types
interface getRequestPackageTypesAction { type: ActionType.GET_REQUEST_PACKAGE_TYPES; }
interface getRequestPackageTypesFailureAction { type: ActionType.GET_REQUEST_PACKAGE_TYPES_FAILURE; payload: string; }
interface getRequestPackageTypesSuccessAction { type: ActionType.GET_REQUEST_PACKAGE_TYPES_SUCCESS; payload: IPackageTypes; }
export type GetRequestPackageTypesActions = getRequestPackageTypesAction | getRequestPackageTypesFailureAction | getRequestPackageTypesSuccessAction;

export type Action = 
    GetConfigActions | SaveConfigActions |
    GetPluginActions | SavePluginActions |
    GetConfigBundleActions | SaveConfigBundleActions |
    GetConfigEventBundleActions | SaveConfigEventBundleActions |
    GetDocumentMappingActions | SaveDocumentMappingActions |
    GetConfigPushbackActions | SaveConfigPushbackActions |
    GetRequestPackageTypesActions;