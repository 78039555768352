import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface DisplayDialogProps {
    open: boolean;
    onClose: () => void;
    title: string;
    json: any;
}
const DisplayDialog: React.FC<DisplayDialogProps> = ({ open, onClose, title, json }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {title}
                <IconButton
                    onClick={onClose}
                    style={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <pre>{JSON.stringify(json, null, 2)}</pre>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export { DisplayDialog };