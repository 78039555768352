import { IAppState } from "../models/IAppState";
import { IControlsOptionsHelper } from "../models/IControlsOptionsHelper";
import { ALL_USERS_ROLE } from "./constants";

export const GetControlOptions = (opts: any, defaultTitle: string, state: IAppState): IControlsOptionsHelper => {
    let isVisible: boolean = false;
    let isEnabled: boolean = false;
    let title = defaultTitle

    if (opts) {
        title = opts.Caption;

        if (opts.Roles?.includes(ALL_USERS_ROLE))
            isEnabled = true;

        opts.Roles?.forEach((r: any) => {
            if (state.userPersonas.includes(r)) {
                isEnabled = true;
            }
        })

        if (state.userId && opts.Users?.includes(state.userId)) {
            isEnabled = true;
        }

        //one last check for master rules
        //and check for isEnabled based on above
        if (!opts.Enabled)
            isEnabled = false;
        if(isEnabled)
            isVisible = true;
        if(!isEnabled && opts.ControlBehavior ==='Visible')
            isVisible = true;
    } 

    return {
        IsEnabled: isEnabled,
        IsVisible: isVisible,
        Title: title
    } as IControlsOptionsHelper;
}