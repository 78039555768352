import React, { useEffect, useState } from 'react';
import {Card, CardContent, Tab, Tabs} from '@mui/material';
import CustomTabPanel from '../shared/CustomTabPanel';
import DTDataGrid from "../../components/shared/config/DTDataGrid";
import {IPackage} from "../../models/configuration/plugin/IConfiguration";
import {evaluationResultsColumns, validDeliveryMethodsColumns} from "./evaluationresultsdata";
import {SpecialDeliveryTypes, FullfillmentShippingOptions, SpecialClosingTypes} from "../../enums/SpecialDeliveryTypes";

interface EvaluationResultsProps {
    packageList: IPackage[] | undefined;
}

const EvaluationResults : React.FC<EvaluationResultsProps> = ({packageList}) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [evaluationResults, setEvaluationResults] = useState<Array<IPackage>>([]);//TODO: Pending Tab0 due to expression dependency
    const [validDeliveryMethods, setValidDeliveryMethods] = useState<Array<IPackage>>([]);

    useEffect(() => {
        if (packageList != null) {
            AddSpecialDeliveryTypes(packageList);
            setValidDeliveryMethods(packageList);
        }
    }, [packageList]);

    const AddSpecialDeliveryTypes = (packageList : IPackage[]) => {
        packageList.forEach(x =>
        {
            x.ValidDeliveryTypes.push(SpecialDeliveryTypes.PrintInPerson); //Adding custom delivery
            //Adding special delivery types for FullFillmentShippingOptions
            if(x.ValidDeliveryTypes.includes("Mail")){
                for(let deliveryType in FullfillmentShippingOptions)
                {
                    x.ValidDeliveryTypes.push(`Mail-${deliveryType}`)
                }
            }
            //Adding special delivery types for SpecialClosingTypes
            if(x.ValidDeliveryTypes.includes("eClose")){
                for(let deliveryType in SpecialClosingTypes)
                {
                    x.ValidDeliveryTypes.push(`eClose-${deliveryType}`)
                }
            }
            x.ValidDeliveryTypes.some(pkg => pkg.startsWith("eSign")) ?? x.ValidDeliveryTypes.push(SpecialDeliveryTypes.ESignSecondaryId);
            x.ValidDeliveryTypes.some(pkg => pkg.startsWith("eDisclose")) ?? x.ValidDeliveryTypes.push(SpecialDeliveryTypes.EDsicloseSecondaryId);
            x.ValidDeliveryTypes.some(pkg => pkg.startsWith("eClose")) ?? x.ValidDeliveryTypes.push(SpecialDeliveryTypes.ECloseSecondaryId);
        })
    }

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    function tabProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

	return (
        <div>
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                margin: 2
            }}
        >
            <CardContent>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Evaluations" {...tabProps(0)} />
                    <Tab label="Valid Delivery Methods" {...tabProps(1)} />
                </Tabs>
                <CustomTabPanel value={tabValue} index={0}>
                    <DTDataGrid
                        data={evaluationResults}
                        columns={evaluationResultsColumns()}
                        tableName="Test"
                        containerSx={{ maxHeight: "60vh" }}
                    />
                </CustomTabPanel>

                <CustomTabPanel value={tabValue} index={1}>
                    <DTDataGrid
                        data={validDeliveryMethods}
                        columns={validDeliveryMethodsColumns()}
                        tableName="ValidDeliveryMethods"
                        containerSx={{ maxHeight: "60vh"}}
                    />
                </CustomTabPanel>
            </CardContent>
        </Card>
    </div>
	);
};

export default EvaluationResults;