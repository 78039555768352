import React, { useState, useEffect } from "react";
import { Box, Card, Stack, Select, InputLabel, FormControl, MenuItem, FormControlLabel, FormGroup, TextField, Checkbox, SelectChangeEvent, CardContent, CardActions, Button, Typography } from "@mui/material";
import GenericDialog from "../../../components/shared/GenericDialog";
import { IDocumentBundle, IDocumentHandlingPackageConfig, IMergerPackageConfig } from "../../../models/configuration/bundles/IBundles";
import DTDataGrid from "../../../components/shared/config/DTDataGrid";
import { useDocumentMappings } from "../../../hooks/useDocumentMappings";
import { mergePackageConfigColumns, packageConfigColumns } from "./configbundlingdata";

interface ConfigDocumentHandlingDialogProps {
    open: boolean;
    documentBundle: IDocumentBundle;
    updateBundle: (bundle: IDocumentBundle) => void;
    closeFxtn: () => void;
}

const OneFolderPerDocument = "OneFolderPerDocument";
const OneFolderPerPackage = "OneFolderPerPackage";
const TitleOneFolderPerDocument = "Document Handling Package Configs";
const TitleOneFolderPerPackage = "Merger Package Configs";

const ConfigDocumentHandlingDialog: React.FC<ConfigDocumentHandlingDialogProps> = ({ open, documentBundle, updateBundle, closeFxtn }) => {

    const [documentBundleState, setDocumentBundleState] = useState<IDocumentBundle>(documentBundle);
    const [selectedRow, setSelectedRow] = useState<number | null>(null);
    const [title, setTitle] = useState<string>(TitleOneFolderPerDocument);

    const documentMappings = useDocumentMappings();
    const documentMapsPrefix = ["All", "AuditLog"];

    useEffect(() => {
        setDocumentBundleState(documentBundle);
    }, [documentBundle]);

    useEffect(() => {
        if (documentBundleState != null) {
            updateBundle(documentBundleState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentBundleState]);

    const handleSelectChange = (e: SelectChangeEvent) => {
        const { name, value } = e.target;
        setTitle(value === OneFolderPerPackage ? TitleOneFolderPerPackage : TitleOneFolderPerDocument);
        handlePropChange(name, value);
    };

    const handlePropChange = (name: string, value: any) => {
        setDocumentBundleState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // * Handling DocumentHandlingPackageConfigs

    const handleItemSelectChangeDocumentHandlingPackageConfig = (
        e: SelectChangeEvent,
        item: IDocumentHandlingPackageConfig
    ) => {
        const { name, value } = e.target;
        handleItemPropChangeDocumentHandlingPackageConfig(name, value, item);
    };

    const handleItemCheckBoxChangeDocumentHandlingPackageConfig = (
        e: React.ChangeEvent<HTMLInputElement>,
        item: IDocumentHandlingPackageConfig
    ) => {
        const { name, checked } = e.target;
        handleItemPropChangeDocumentHandlingPackageConfig(name, checked, item);
    };

    const handleItemInputChangeDocumentHandlingPackageConfig = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        item: IDocumentHandlingPackageConfig
    ) => {
        const { name, value } = e.target;
        handleItemPropChangeDocumentHandlingPackageConfig(name, value, item);
    };

    const handleItemPropChangeDocumentHandlingPackageConfig = (
        name: string,
        value: any,
        item: IDocumentHandlingPackageConfig
    ) => {
        const documentIndexStr = "DocumentIndex";
        // find new document map value 
        const map = documentMappings?.Configuration.DocumentMaps.find((map) => map.DocumentIndex === value);
        const docIndexChange = value !== item.DocumentIndex; // drop down change 
        (item as any)[name] = name === documentIndexStr ? value.toString() : value;
        setDocumentBundleState((prevState) => {
            const updatedConfigs = prevState.DocumentHandlingPackageConfigs.map((config) => {
                if (docIndexChange && map && config.DocumentIndex === item.DocumentIndex) {
                    return {
                        ...config,
                        DocumentName: map.ConformXDocumentName,
                        EncompassFolder: map.EncompassDocumentName,
                    };
                }
                return config;
            });
            return {
                ...prevState,
                DocumentHandlingPackageConfigs: updatedConfigs,
            };
        });
    };

    // * Handling MergerPackageConfigs

    const handleItemSelectChangeMergerPackageConfig = (
        e: SelectChangeEvent,
        item: IMergerPackageConfig
    ) => {
        const { name, value } = e.target;
        handleItemPropChangeMergerPackageConfig(name, value, item);
    };

    const handleItemCheckBoxChangeMergerPackageConfig = (
        e: React.ChangeEvent<HTMLInputElement>,
        item: IMergerPackageConfig
    ) => {
        const { name, checked } = e.target;
        handleItemPropChangeMergerPackageConfig(name, checked, item);
    };

    const handleItemInputChangeMergerPackageConfig = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        item: IMergerPackageConfig
    ) => {
        const { name, value } = e.target;
        handleItemPropChangeMergerPackageConfig(name, value, item);
    };

    const handleItemPropChangeMergerPackageConfig = (
        name: string,
        value: any,
        item: IMergerPackageConfig
    ) => {
        //  to force an array on the mergeList 
        const mergeListStr = "MergeList";
        (item as any)[name] = name === mergeListStr ? value.split(",") : value;
        setDocumentBundleState((prevState) => ({
            ...prevState,
        }));
    };

    const addRow = () => {
        if (documentBundleState?.BundlingOption === OneFolderPerDocument) {
            const bundleItemsDHPCs = [
                ...documentBundleState?.DocumentHandlingPackageConfigs,
            ];
            const blankItemDHPC: IDocumentHandlingPackageConfig = {
                DocumentIndex: null,
                DocumentName: "",
                EncompassFolder: "",
                Suffix: "",
                ReuseFolder: false,
                EncompassAttachment: "",
            };

            bundleItemsDHPCs.push(blankItemDHPC);

            setDocumentBundleState((prevState) => ({
                ...prevState,
                DocumentHandlingPackageConfigs: bundleItemsDHPCs,
            }));
        }
        if (documentBundleState?.BundlingOption === OneFolderPerPackage) {
            const bundleItemMPCs = [
                ...documentBundleState?.MergerPackageConfigs
            ];
            const blankItemMPCs: IMergerPackageConfig = {
                MergeList: [],
                EncompassFolder: "",
                Suffix: "",
                ReuseFolder: false,
                EncompassAttachment: "",
            };

            bundleItemMPCs.push(blankItemMPCs);

            setDocumentBundleState((prevState) => ({
                ...prevState,
                MergerPackageConfigs: bundleItemMPCs,
            }));
        }
    };
    const removeRow = () => {
        if (selectedRow === null) return;
        if (documentBundleState?.BundlingOption === OneFolderPerDocument) {
            const updatedConfigs = documentBundleState.DocumentHandlingPackageConfigs.filter(
                (_, index) => index !== selectedRow
            );
            setDocumentBundleState((prevState) => ({
                ...prevState,
                DocumentHandlingPackageConfigs: updatedConfigs,
            }));
        } else if (documentBundleState?.BundlingOption === OneFolderPerPackage) {
            const updatedConfigs = documentBundleState.MergerPackageConfigs.filter(
                (_, index) => index !== selectedRow
            );
            setDocumentBundleState((prevState) => ({
                ...prevState,
                MergerPackageConfigs: updatedConfigs,
            }));
        }
        setSelectedRow(null);
    };

    const handleRowClick = (rowIndex: number) => {
        setSelectedRow(rowIndex);
    };

    if (documentBundleState == null) return <></>;

    if (documentMappings == null) return <></>;

    return (
        <GenericDialog
            open={open}
            title={"Document Handling Configuration"}
            onClose={closeFxtn}
            cancelButtonText="Close"
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    height: "100%",
                }}
            >
                <Stack direction="row" justifyContent="start" spacing={1}>
                    <Box sx={{ flex: 1 }} className={["flexLeft"].join(" ")}>
                        <TextField
                            sx={{ marginTop: "10px" }}
                            label="Package ID"
                            name="PackageID"
                            value={documentBundleState?.PackageId}
                            fullWidth
                            variant="outlined"
                        />
                    </Box>

                    <Box sx={{ flex: 1 }} className={["flexRight"].join(" ")}>
                        {documentBundleState?.BundlingOption === OneFolderPerDocument ? (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={documentBundleState?.DocumentHandlingDuplicate ?? false}
                                            onChange={(e) =>
                                                handlePropChange(
                                                    "DocumentHandlingDuplicate",
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    }
                                    label="Allow Document Handling Duplication"
                                />
                            </FormGroup>
                        ) : (
                            <></>
                        )}
                    </Box>
                </Stack>

                <Stack direction="row" justifyContent="start" spacing={1}>
                    <Box sx={{ flex: 1 }} className={["flexLeft"].join(" ")}>
                        <FormControl sx={{ flex: 1 }}>
                            <InputLabel>{"Bundling Option"}</InputLabel>
                            <Select
                                sx={{ marginTop: "10px" }}
                                label="Bundling Option"
                                name="BundlingOption"
                                value={documentBundleState?.BundlingOption}
                                onChange={handleSelectChange}
                                fullWidth
                                variant="outlined"
                            >
                                <MenuItem value={OneFolderPerDocument}>
                                    One Folder Per Document
                                </MenuItem>
                                <MenuItem value={OneFolderPerPackage}>
                                    One Folder Per Package
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{ flex: 1 }} className={["flexRight"].join(" ")}>
                        {documentBundleState?.BundlingOption === OneFolderPerPackage ? (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={documentBundleState?.MergerConfigDuplicate ?? false}
                                            onChange={(e) =>
                                                handlePropChange(
                                                    "MergerConfigDuplicate",
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    }
                                    label="Allow Merger Duplication"
                                />
                            </FormGroup>
                        ) : (
                            <></>
                        )}
                    </Box>
                </Stack>

                <Card sx={{ mt: 1 }}>
                    <CardContent>
                        <Typography variant="subtitle1" component="h2">{title}</Typography>
                        {documentBundleState?.BundlingOption === OneFolderPerDocument ? (
                            <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }} className={["flexRight"].join(" ")}>
                                <DTDataGrid
                                    columns={packageConfigColumns(
                                        handleItemSelectChangeDocumentHandlingPackageConfig,
                                        handleItemCheckBoxChangeDocumentHandlingPackageConfig,
                                        handleItemInputChangeDocumentHandlingPackageConfig,
                                        [...documentMapsPrefix, ...documentMappings?.Configuration?.DocumentMaps.map(x => x.DocumentIndex) ?? []]
                                    )}
                                    data={
                                        documentBundleState?.DocumentHandlingPackageConfigs ?? []
                                    }
                                    tableName="packageConfigs"
                                    containerSx={{ maxHeight: "30vh", minHeight: "30vh", width: "100%" }}
                                    onRowClick={handleRowClick}
                                />
                            </Box>
                        ) : (
                            <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }} className={["flexRight"].join(" ")}>
                                <DTDataGrid
                                    columns={mergePackageConfigColumns(
                                        handleItemSelectChangeMergerPackageConfig,
                                        handleItemCheckBoxChangeMergerPackageConfig,
                                        handleItemInputChangeMergerPackageConfig,
                                        [...documentMapsPrefix, ...documentMappings?.Configuration?.DocumentMaps.map(x => x.DocumentIndex) ?? []]
                                    )}
                                    data={documentBundleState?.MergerPackageConfigs ?? []}
                                    tableName="mergepackageConfigs"
                                    containerSx={{ maxHeight: "30vh", minHeight: "30vh" }}
                                    onRowClick={handleRowClick}
                                />
                            </Box>
                        )}

                        <CardActions>
                            <Button variant="outlined" size="small" onClick={addRow}>
                                Add
                            </Button>
                            <Button variant="outlined" size="small" onClick={removeRow}>
                                Remove
                            </Button>
                        </CardActions>
                    </CardContent>
                </Card>
            </Box>
        </GenericDialog>
    );
};

export default ConfigDocumentHandlingDialog;