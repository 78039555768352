import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, SelectChangeEvent, Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import "../../assets/style.css";
import ConfigLoad from "../../components/shared/config/ConfigLoad";
import ControlsList from "../../components/configuration/otherControls/ControlsList";
import ControlDetails from "../../components/configuration/otherControls/ControlDetails";
import TabControl from "../../components/configuration/otherControls/TabControl";
import EncompassService from "../../services/encompassService";
import { IOtherControl } from "../../models/configuration/plugin/IConfiguration";
import { RootState, store } from "../../state/store";
import { useSelector } from "react-redux";
import ConfigTopBar from "../../components/shared/config/ConfigTopBar";
import ConfigBottomBar from "../../components/shared/config/ConfigBottomBar";
import { ConfigurationDataType, useConfigurationData } from "../../hooks/useConfigurationData";
import { useActions } from "../../hooks/useActions";

const ConfigOtherControls: React.FC = () => {

    const { saveConfigOtherControlsData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);

    // State 
    const [otherControls, setOtherControls] = useState<IOtherControl[]>([]);
    const [selectedControl, setSelectedControl] = useState<IOtherControl | null>(null);
    const [selectedControlIdx, setSelectedControlIdx] = useState<number | null>(null);

    // Sub States 
    const [selectedConfigId, setSelectedConfigId] = useState<string>('');
    const [tabIndex] = useState<number>(1);
    const [errorMessage, setErrorMessage] = useState<string>('');

    // State Confirmations
    const [alertOpen, setAlertOpen] = useState(false);
    const [saveError, setSaveError] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('info');
    const [saving, setSaving] = useState<boolean>(false);

    // Loading Data 
    const hasFetchedData = useRef(false);
    const { data, error, loading } = useConfigurationData(ConfigurationDataType.OTHER_CONTROLS);
    const mData = useMemo(() => data, [data]);

    useEffect(() => {
        if (!hasFetchedData.current && data) {
            const configId = EncompassService.getConfigId() as string;
            setSelectedConfigId(configId);
            setOtherControls(mData); // Change this
            hasFetchedData.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    // Handling Loading Error 
    useEffect(() => {
        if (!error) {
            setErrorMessage(error);
        }
    }, [error]);

    // Handling Saving Error 
    useEffect(() => {
        if (!saveError && data !== null) {
            setOtherControls(mData);  // Only update local data if there is no save Error condition 
        }
    }, [data, saveError, mData]);

    const selectControl = (index: number, id: string) => {
        setSelectedControlIdx(index);
        setSelectedControl(otherControls?.find(c => c.Id === id) || null);
        const configId = EncompassService.getConfigId() as string;
        setSelectedConfigId(configId);
    }

    useEffect(() => {
        saveState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otherControls])

    useEffect(() => {

        if (selectedControl != null && otherControls) {
            setOtherControls(otherControls => {
                return otherControls?.map(control =>
                    control.Id === selectedControl.Id ? selectedControl : control)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedControl])

    // Property Changes
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => { handleControlPropChange("Enabled", event.target.checked); };
    const handleBehaviorChange = (event: SelectChangeEvent) => { handleControlPropChange("ControlBehavior", event.target.value); };
    const handleCaptionChange = (event: React.ChangeEvent<HTMLInputElement>) => { handleControlPropChange("Caption", event.target.value); };
    const handleControlPropChange = (property: keyof IOtherControl, value: any) => {
        if (selectedControl) {
            const updateControl = { ...selectedControl, [property]: value };
            setSelectedControl(updateControl);
        }
    }

    const saveState = () => {
        try {
            if (otherControls.length === 0) return;
            const configPlugInData = store.getState().configPlugInData.data;
            const updatedConfigPlugInData = {
                ...configPlugInData,
                Configuration: {
                    ...configPlugInData?.Configuration,
                    OtherControls: otherControls
                },
            };
            store.dispatch({
                type: 'save_plugin_data_success',
                payload: updatedConfigPlugInData
            });

        } catch (e) {
            console.log("Error Saving State: ", e);
            openAlert(`Error Saving State: ${e}`, 'error');
        }
    }

    const save = async () => {
        try {
            setSaving(true);
            await saveConfigOtherControlsData(selectedConfigId, otherControls, accessToken);
            openAlert("Successfully Saved Changes", "success");
            setSaveError(false);
            setErrorMessage('');
        } catch (e) {
            console.error("Error saving data");
            setSaveError(true);
            if (e instanceof Error) {
                setErrorMessage(`Failed to save data: ${e.message}`);
            } else {
                setErrorMessage('Failed to save data: Unknown error');
            }
        } finally {
            setSaving(false);
        }
    }

    const updateSelectedControl = (updatedControl: IOtherControl) => {
        setSelectedControl(updatedControl);
    }

    const onUpdateUsers = (users: string[]) => {
        setSelectedControl(
            selectedControl ? { ...selectedControl, Users: [...users] } : null,
        )
    }

    // Alert Handling 
    const handleSnackBarClose = (_event: any) => { setAlertOpen(false); }
    const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);
    }

    return (
        <>
            <section>
                <div>
                    {(loading || saving) && <ConfigLoad loading={loading} />}
                </div>
                <div>

                    <Box
                        mt={1} component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            overflow: "auto",
                            minHeight: '100vh',
                            margin: "2px"
                        }}
                    >

                        <ConfigTopBar save={save} error={error || errorMessage || null} />
                        <div className="configOtherControlsContainer">
                            {/* Left Side */}
                            <div className="configOtherControls-Left">
                                <ControlsList
                                    otherControls={otherControls}
                                    selectedControlIdx={selectedControlIdx}
                                    selectControl={selectControl} />
                            </div>
                            <div style={{ pointerEvents: selectedControl ? 'auto' : 'none', opacity: selectedControl ? 1 : 0.5 }}>
                                {/* Right Side */}
                                <div className="configOtherControls-Right">
                                    <Box sx={{ margin: 5 }}>
                                        <ControlDetails
                                            selectedControl={selectedControl}
                                            handleOnEnabledChange={handleCheckboxChange}
                                            handleOnBehaviorChange={handleBehaviorChange}
                                            handleOnCaptionChange={handleCaptionChange}
                                        />
                                    </Box>
                                    <Box>
                                        <TabControl
                                            tabIndex={tabIndex}
                                            selectedControl={selectedControl}
                                            updateParentControl={updateSelectedControl}
                                            sendUserUpdate={onUpdateUsers}
                                        />
                                    </Box>
                                </div>
                            </div>
                        </div>

                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={alertOpen}
                            autoHideDuration={4000}
                            onClose={handleSnackBarClose}
                        >
                            <MuiAlert
                                onClose={handleSnackBarClose}
                                severity={alertSeverity}
                                sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                                {alertMessage}
                            </MuiAlert>
                        </Snackbar>
                    </Box>
                    <ConfigBottomBar save={save} />
                </div>
            </section>
        </>
    )
}

export default ConfigOtherControls;