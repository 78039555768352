import { Dispatch } from "redux";
import { Action, ActionType } from "../../actions";
import RequestService from "../../../services/requestService";
import { IPackageTypes } from  "../../../models/request/IRequest";

// This action creator gets the server config values from an api call or state
const getRequestPackageTypesData = (configId: string, token: string | null): (dispatch: Dispatch<Action>, getState: () => any) => Promise<void> => {
    return async (dispatch: Dispatch<Action>, getState: () => any) => {

        dispatch({ type: ActionType.GET_REQUEST_PACKAGE_TYPES });

        try {

            const data = getState().requestPackageTypes.data;

            if (data !== null) { // Checking for exiting state data  
                dispatch({
                    type: ActionType.GET_REQUEST_PACKAGE_TYPES_SUCCESS,
                    payload: data
                });
                return;
            } else {
                const payload = await new RequestService().get<IPackageTypes>(token, "packagetypes", configId);

                dispatch({
                    type: ActionType.GET_REQUEST_PACKAGE_TYPES_SUCCESS,
                    payload: payload,
                });
            }

        } catch (error: any) {

            console.error("Get Request Package Types Data");
            if (error instanceof Error) {
                var payloadErrorMessage = `Failed to fetch Request Package Types Data: (${error.message})`;
                dispatch({
                    type: ActionType.GET_REQUEST_PACKAGE_TYPES_FAILURE,
                    payload: payloadErrorMessage,
                });
            }

        }
    }
}

export default getRequestPackageTypesData;