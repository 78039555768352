import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import IIntegration from "../../models/configuration/servers/IIntegration";

// This action creator gets the server config values from an api call or state
const getConfigServerData = (configId: string, token: string | null): (dispatch: Dispatch<Action>, getState: () => any) => Promise<void> => {
    return async (dispatch: Dispatch<Action>, getState: () => any) => {


        dispatch({ type: ActionType.GET_CONFIG_SERVERS });

        try {

            const { data: serverData } = getState().configServers;

            if (serverData) { // * Cache
                dispatch({
                    type: ActionType.GET_CONFIG_SERVERS_SUCCESS,
                    payload: serverData
                });
            }
            else {
                const payload = await new PluginService().getConfig<IIntegration>(token, "integration", configId);
                dispatch({
                    type: ActionType.GET_CONFIG_SERVERS_SUCCESS,
                    payload: payload,
                });
            }

        } catch (error: any) {

            console.error("Get Config Server Data");
            if (error instanceof Error) {
                const payloadErrorMessage = `Failed to fetch Config Servers Data: (${error.message})`;
                dispatch({
                    type: ActionType.GET_CONFIG_SERVERS_FAILURE,
                    payload: payloadErrorMessage,
                });
            }

        }
    }
}

export default getConfigServerData;