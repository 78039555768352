import React, { useState, useEffect } from "react";
import { Box, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import "../assets/style.css";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from '../state/store';
import { setLoading, setPage } from "../state/appSlice";
import { useTypedSelector } from "../hooks/useTypedSelector";

const SideBar: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const appState = useSelector((state: RootState) => state.appSlice)
    const packages = useTypedSelector((state) => state.configPlugInData.data?.Configuration.Packages);
    const needSetupRedirect = useTypedSelector((state) => state.appSlice.needSetupRedirect);
    const [sidebarLinks, setSidebarLinks] = useState<Array<{ 'name': string, 'path': string }>>([]);
    const [homeVisibility, setHomeVisibility] = useState<'visible' | 'hidden' | 'collapse'>('visible')
    const [showConfigLink, setShowConfigLink] = useState(true);

    const configurationLinks = [
        { name: 'Servers', path: './configuration/ConfigServers' },
        { name: 'Packages', path: './configuration/ConfigPackages' },
        { name: 'Delivery Methods', path: './configuration/ConfigDeliveryMethods' },
        { name: 'Other Controls', path: './configuration/ConfigOtherControls' },
        { name: 'Send Validations', path: './configuration/ConfigSendValidations' },
        { name: 'Response Messages', path: './configuration/ConfigResponseMessages' },
        { name: 'Bundling', path: './configuration/ConfigBundling' },
        { name: 'Misc', path: './configuration/ConfigMisc' },
        { name: 'Pushback Configuration', path: './configuration/ConfigPushback' },
    ];

    useEffect(() => {
        let sideGroupsList: Array<{ 'name': string, 'path': string }> = [];
        const packageSort = packages?.map(pkg => ({ ...pkg })).sort((a, b) => (a.Order ?? 999) - (b.Order ?? 999)) ?? [];
        if (packageSort) {
            for (let i = 0; i < packageSort.length; i++) {
                if (!sideGroupsList.some(g => g.name === packageSort[i].Group) && !packageSort[i].ParentPackageId)
                    sideGroupsList.push({ 'name': packageSort[i].Group, 'path': './Order' });
            }
            setSidebarLinks(sideGroupsList);
            if (packageSort.length > 0 && (appState.page === '/' || !appState.page)) {
                dispatch(setPage(sideGroupsList[0]?.name ?? ""));
                navigate("/Order");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [packages])

    useEffect(() => {
        if (needSetupRedirect) { //Router is silly. Can't redirect from App.tsx
            setCurrentPage("Servers", "hidden");
            dispatch(setLoading(false));
            navigate("/configuration/ConfigServers");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needSetupRedirect])

    useEffect(() => {
        setShowConfigLink(appState.isAdmin ?? false)
    }, [appState.isAdmin])

    const setCurrentPage = (page: string, homeVisibility: 'visible' | 'hidden' | 'collapse') => {
        dispatch(setPage(page));
        setHomeVisibility(homeVisibility)
    }

    return (
        <>
            <Box sx={{ height: "100%" }} >
                <List sx={{ height: "98%", padding: 0 }}>
                    {homeVisibility === 'visible' &&
                        <>
                            {sidebarLinks.map((link, index) => (
                                <ListItem key={index} disablePadding>
                                    <ListItemButton
                                        sx={{ color: 'white' }}
                                        selected={appState.page === link.name}
                                        component={NavLink} to={link.path}
                                        onClick={() => setCurrentPage(link.name, 'visible')}   >
                                        <ListItemText primary={link.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                            {showConfigLink && 
                                <ListItem disablePadding  >
                                    <ListItemButton
                                        sx={{ color: 'white' }}
                                        component={NavLink} to={'./configuration/ConfigServers'}
                                        onClick={() => setCurrentPage('Servers', 'hidden')}   >
                                        <ListItemText primary="Configuration" />
                                    </ListItemButton>
                                </ListItem>
                            }
                        </>
                    }

                    {homeVisibility !== 'visible' &&
                        <>
                            {configurationLinks.map((link, index) => (
                                <ListItem key={index} disablePadding >
                                    <ListItemButton
                                        sx={{ color: 'white' }}
                                        selected={appState.page === link.name}
                                        component={NavLink} to={link.path}
                                        onClick={() => setCurrentPage(link.name, 'hidden')}   >
                                        <ListItemText primary={link.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                            <ListItem disablePadding  >
                                <ListItemButton
                                    sx={{ color: 'white' }}
                                    component={NavLink} to='./Order'
                                    onClick={() => setCurrentPage(sidebarLinks[0].name, 'visible')}   >
                                    <ListItemText primary="Home" />
                                </ListItemButton>
                            </ListItem>
                        </>
                    }
                </List>
            </Box>
        </>
    );
};

export default SideBar;