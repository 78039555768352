import { SvgIconComponent } from "@mui/icons-material";

export interface IDataColumn {
    name: string | null;
    fieldId: string;
    datatype: DTDataGridColumnType;
    callbackFunction?: (e : any, item : any) => void;
    foreColor?: string;
    linkText?: string
    alignment?: DTDataGridAlignment,
    dataFunction?: (item : any) => void;
    dataChangeFunction?: (e: any, item : any) => void;
    foreColorFunction?: (item : any) => void;
    backColorFunction?: (item : any) => void;
    dataOptions?: Array<any>;
    readonly?: boolean,
    editOnClick?: boolean,
    displayImage?: SvgIconComponent,
    headerClass?: string,
    cellClass?: string,
    sortable?: boolean
  }

  export enum DTDataGridColumnType {
    text,
    checkbox,
    dropdownlist,
    link,
    image
  }

  export enum  DTDataGridAlignment {
    left,
    right,
    center
  }