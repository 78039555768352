import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import { IBundleConfiguration, IDocumentBundle } from "../../models/configuration/bundles/IBundles";

const saveConfigBundleData = (configId: string, updatedBundlingData: Array<IDocumentBundle>, token: string | null) => {
    return async (dispatch: Dispatch<Action>, getState: () => any) => {

        dispatch({
            type: ActionType.SAVE_CONFIG_BUNDLE_DATA
        });

        const pluginService = new PluginService();

        try {
            if (!updatedBundlingData || updatedBundlingData.length === 0) {
                throw new Error("No Bundles to save");
            }

            const requestBody = {
                "ConfigurationId": configId,
                "Configuration": {
                    "Bundling": updatedBundlingData
                }
            };
            await pluginService.saveConfig<IBundleConfiguration>(token, "document-bundling", configId, requestBody);

            dispatch({
                type: ActionType.SAVE_CONFIG_BUNDLE_DATA_SUCCESS,
                payload: requestBody
            });

            return Promise.resolve();

        } catch (error) {
            console.log("saveConfigbundleData --> FAILURE");
            if (error instanceof Error) {
                const payloadErrorMessage = `Failed to Save Bundles Configuration Data: (${error.message})`;
                dispatch({
                    type: ActionType.SAVE_CONFIG_BUNDLE_DATA_FAILURE,
                    payload: payloadErrorMessage
                });
                throw new Error(payloadErrorMessage);
            }
        }
    }
}

export default saveConfigBundleData;