// @ts-ignore
import host from '@elliemae/em-ssf-guest';

///Singleton to handle all of the Encompass Guest JS
export default class ElliHost {

    //Handling the singleton stuff.
    private static instance: ElliHost;
    public static getInstance(): ElliHost {
        if (!this.instance)
            this.instance = new ElliHost();
        return this.instance;
    }

    public transactionObject: any;
    public applicationObject: any;

    //these are async. 
    public async _getTransactionObject() {
        this.transactionObject = await host.getObject('transaction');
    }
    public async _getApplicationObject() {
        this.applicationObject = await host.getObject('application');
    }



    //constructing it
    private constructor() {
        host.connect();
        host.ready();
    } 
    /*
        FETCHING ORIGIN:
            On first fetch, this will set the origin expiry.
            each proceeding event will use refresh origin.
            
            The origin Id and Token expire after 300, and if we need to refetch the origin, the token/ID must be up to date.
            We aren't currently needing the refresh, but it is good to have just in case. 
            https://docs.partnerconnect.elliemae.com/partnerconnect/reference/transactionrefreshorigin  

            The buffer time allows for a few seconds of overlap should the call take longer than usual.
    */
    private bufferTime = 290 * 1000; 
    private originExpiry: Date | null = null;
    private originContext = null;
    private _originTimer: NodeJS.Timeout | null = null; 
    public async getOrigin() {

        if (this._originTimer)
            clearInterval(this._originTimer);

        if (!this.transactionObject)
            await this._getTransactionObject();

        const currentTime = new Date();

        if (this.originExpiry && currentTime >= this.originExpiry) {
            this.originContext = await this.transactionObject.refreshOrigin();
            this.originExpiry = new Date(new Date().getTime() + this.bufferTime);
        }
        else if (!this.originExpiry) {
            this.originContext = await this.transactionObject.getOrigin();
            this.originExpiry = new Date(new Date().getTime() + this.bufferTime);
        }

        this._originTimer = setInterval(async () => {
            await this.getOrigin()
        }, this.bufferTime + 1000)

        return this.originContext;
    }

    public async createTransaction(request: any) {
        if (!this.transactionObject)
            await this._getTransactionObject();

        const transactionId = await this.transactionObject.create({ "request": request });
        return transactionId;
    }

    public async closeApplication() {
        if (!this.transactionObject)
            await this._getTransactionObject();
        const close = await this.transactionObject.close();
        return close;
    }

    public async openResource(resourceReference: any) {
        if (!this.applicationObject)
            await this._getApplicationObject();

        await this.applicationObject.open(resourceReference);
    }

    public async openResourceInModal(resourceReference: any) {
        if (!this.applicationObject)
            await this._getApplicationObject();

        await this.applicationObject.openModal(resourceReference);
    }

    public async getResources() {
        if (!this.applicationObject)
            await this._getApplicationObject();

        const files = await this.applicationObject.performAction('getAvailableResources',
            {
                'allowedFileTypes': ['image/jpeg', 'image/png', ".pdf", ".docx", '.gif', '.txt', '.tif', '.bmp', '.doc', '.wpd', '.xls', 'xlsx'],
                'allowedSourceTypes': ['efolder', 'local']
            })
        return files;
    }

    public async getRoles() {
        if (!this.applicationObject)
            await this._getApplicationObject();

        const roles = await this.applicationObject.performAction('getRoles');
        return roles;
    }

    public async updateCredentials() {
        if (!this.applicationObject)
            await this._getApplicationObject();

        return await this.applicationObject.performAction("updateCredentials");
    }

}