export const ReceivedDate_Population_Items = [
  "Consent",
  "Review",
  "SignAndSubmit",
];
export const Minutes_Min_Default_Value = 1;
export const Minutes_Max_Default_Value = 2000;
export const Concurrency_Min_Default_Value = 1;
export const Concurrency_Max_Default_Value = 2000;
export const Attempts_Min_Default_Value = 0;
export const Attempts_Max_Default_Value = 999;
export const Step_Default_Value = 1;

export const Get_Minutes_Pushback_DefaultValue = (configVal: any) => {
  if (configVal && !isNaN(configVal)) {
    if (configVal < Minutes_Min_Default_Value) return Minutes_Min_Default_Value;
    else if (configVal > Minutes_Max_Default_Value)
      return Minutes_Max_Default_Value;
    else return configVal / 60;
  }
};

export const Get_Attempts_Pushback_DefaultValue = (configVal: any) => {
  if (configVal && !isNaN(configVal)) {
    if (configVal < Attempts_Min_Default_Value)
      return Attempts_Min_Default_Value;
    else if (configVal > Attempts_Max_Default_Value)
      return Attempts_Max_Default_Value;
    else return configVal;
  }
};

export const Get_Concurrency_Pushback_DefaultValue = (configVal: any) => {
  if (configVal && !isNaN(configVal)) {
    if (configVal < Concurrency_Min_Default_Value)
      return Concurrency_Min_Default_Value;
    else if (configVal > Concurrency_Max_Default_Value)
      return Concurrency_Max_Default_Value;
    else return configVal;
  }
};

export const Get_Seconds_Pushback_DefaultValue = (configVal: any) => {
  return configVal * 60;
};