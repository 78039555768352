import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import { IBundleConfiguration } from "../../models/configuration/bundles/IBundles";

// This action creator gets the server config values from an api call or state
const getConfigDocumentBundleData = (configId: string, token: string | null): (dispatch: Dispatch<Action>, getState: () => any) => Promise<void> => {
    return async (dispatch: Dispatch<Action>, getState: () => any) => {

        dispatch({ type: ActionType.GET_CONFIG_BUNDLE_DATA });

        try {

            const { data: documentBundles } = getState().configDocumentBundles;

            if (documentBundles) { // * Cache
                dispatch({
                    type: ActionType.GET_CONFIG_BUNDLE_DATA_SUCCESS,
                    payload: documentBundles
                });
            } else {
                const payload = await new PluginService().getConfig<IBundleConfiguration>(token, "document-bundling", configId);
                dispatch({
                    type: ActionType.GET_CONFIG_BUNDLE_DATA_SUCCESS,
                    payload: payload
                });
            }

        } catch (error) {
            console.error("Get Config Document Bundle Data");
            const payloadErrorMessage = error instanceof Error
                ? `Failed to fetch Config Document Bundle Data: (${error.message})`
                : "Failed to fetch Config Document Bundle Data: (Unknown error)";
            dispatch({
                type: ActionType.GET_CONFIG_BUNDLE_DATA_FAILURE,
                payload: payloadErrorMessage,
            });
        }
    }
}

export default getConfigDocumentBundleData;