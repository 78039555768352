import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  SnackbarCloseReason,
  Alert,
  TextField,
} from "@mui/material";
import { Unstable_NumberInput as NumberInput } from "@mui/base/Unstable_NumberInput";
import {
  StyledInputRoot,
  StyledInputElement,
  StyledButton,
} from "../../assets/styles/NumberInputStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../assets/style.css";
import ConfigLoad from "../../components/shared/config/ConfigLoad";
import ConfigBottomBar from "../../components/shared/config/ConfigBottomBar";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import EncompassService from "../../services/encompassService";
import { IConfiguration } from "../../models/configuration/pushback/IConfiguration";
import {
  ReceivedDate_Population_Items,
  Minutes_Min_Default_Value,
  Minutes_Max_Default_Value,
  Concurrency_Min_Default_Value,
  Concurrency_Max_Default_Value,
  Attempts_Min_Default_Value,
  Attempts_Max_Default_Value,
  Get_Minutes_Pushback_DefaultValue,
  Get_Attempts_Pushback_DefaultValue,
  Get_Concurrency_Pushback_DefaultValue,
  Get_Seconds_Pushback_DefaultValue,
  Step_Default_Value,
} from "../../constants/PushbackConstants";

const ENCOMPASS_REQUEST_API_URI = process.env.REACT_APP_DT_REQUEST_API_URI;
const ENCOMPASS_PLUGIN_API_URI = process.env.REACT_APP_DT_PLUGIN_API_URI;
const ENCOMPASS_TOKEN_ENDPOINT_API_URI =
  process.env.REACT_APP_DT_TOKEN_ENDPOINT_API_URI;

const ConfigPushback: React.FC = () => {
  //
  const { getConfigPushbackData, saveConfigPushbackData } =
    useActions();
  const { loading, error, data } = useTypedSelector(
    (state) => state.configPushbackDefaults
  );
  const [pushbackDefaultData, setPushbackDefaultData] =
    useState<IConfiguration | null>(data?.Configuration || null);
  const accessToken = useTypedSelector((state) => state.appSlice.accessToken);
  // Pushback state
  const [selectedConfigId, setSelectedConfigId] = useState<string>("");
  const [requestUri, setRequestUri] = useState("");
  const [pluginUri, setPluginUri] = useState("");
  const [tokenUri, setTokenUri] = useState("");
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState("");
  const [saveError, setSaveError] = useState<boolean>(false);

  const hasFetchedData = useRef(false);

  const fetchPushbackData = useCallback(
    (configId: string) => {
      getConfigPushbackData(configId, accessToken);
    },
    [getConfigPushbackData, accessToken]
  );

  // Save Page Data
  const savePushbackConfigData = useCallback(
    async (configId: string, data: IConfiguration) => {
      saveConfigPushbackData(configId, data, accessToken);
    },
    [saveConfigPushbackData, accessToken]
  );

  useEffect(() => {
    if (error) {
      setAlert(error);
      setOpen(true);
    }
  }, [error]);

  useEffect(() => {
    if (!hasFetchedData.current && !data) {
      const configId = EncompassService.getConfigId() as string;
      setSelectedConfigId(configId);
      const delayFetch = setTimeout(() => {
        fetchPushbackData(configId);
        initializeScreenData();
        hasFetchedData.current = true;
      }, 500);
      return () => clearTimeout(delayFetch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle Data
  useEffect(() => {
    if (!saveError && data != null) {
      setPushbackDefaultData(data?.Configuration); // Only update local data if there is no save Error condition
      initializeScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.Configuration]);

  const updatePushbackData = (key: string, value: any) => {
    setPushbackDefaultData((prevData) => {
      if (!prevData) return null;
      return {
        ...prevData,
        [key]: value,
      };
    });
  };

  // Toast
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setAlert("");
    setSaveError(false);
  };

  function cacheEndpoints() {
    localStorage.setItem("requestUri", requestUri);
    localStorage.setItem("pluginUri", pluginUri);
    localStorage.setItem("tokenUri", tokenUri);
  }

  function initializeScreenData() {
    setRequestUri(
      localStorage.getItem("requestUri") ||
        (ENCOMPASS_REQUEST_API_URI as string)
    );
    setPluginUri(
      localStorage.getItem("pluginUri") || (ENCOMPASS_PLUGIN_API_URI as string)
    );
    setTokenUri(
      localStorage.getItem("tokenUri") ||
        (ENCOMPASS_TOKEN_ENDPOINT_API_URI as string)
    );
    if (!selectedConfigId) {
      setSelectedConfigId(getConfigId());
    }
  }

  const getConfigId = () => {
    if (selectedConfigId) return selectedConfigId;
    return EncompassService.getConfigId() as string;
  };

  const save = async () => {
    cacheEndpoints();
    try {
      savePushbackConfigData(
        getConfigId(),
        pushbackDefaultData as IConfiguration
      );
      setAlert("Successfully Saved Pushback Config Changes");
      setOpen(true);
      setSaveError(false);
    } catch (e) {
      setSaveError(true);
    }
  };

  return (
    <section>
      <div>
        {loading && (
          <ConfigLoad loading={loading} message="Loading Configuration" />
        )}
      </div>
      <Box
        mt={1}
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          overflow: "auto",
          minHeight: "100vh",
          margin: "2px",
        }}
      >
        <Card sx={{ margin: "10px" }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Pushback Configuration
            </Typography>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              ></AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <Typography>Received Date Population</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={pushbackDefaultData?.ReceivedDatePopulation || ""}
                      onChange={(event) =>
                        updatePushbackData(
                          "ReceivedDatePopulation",
                          event.target.value
                        )
                      }
                    >
                      {ReceivedDate_Population_Items.map((item, index) => (
                        <MenuItem
                          sx={{ padding: "2px 8px" }}
                          key={index}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Max Concurrency</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      value={
                        pushbackDefaultData?.MaxConcurrency
                          ? Get_Concurrency_Pushback_DefaultValue(
                              pushbackDefaultData?.MaxConcurrency
                            )
                          : Concurrency_Min_Default_Value
                      }
                      onChange={(event, val) => {
                        updatePushbackData("MaxConcurrency", val);
                      }}
                      slots={{
                        root: StyledInputRoot,
                        input: StyledInputElement,
                        incrementButton: StyledButton,
                        decrementButton: StyledButton,
                      }}
                      slotProps={{
                        incrementButton: {
                          children: "▴",
                        },
                        decrementButton: {
                          children: "▾",
                        },
                      }}
                      min={Concurrency_Min_Default_Value}
                      max={Concurrency_Max_Default_Value}
                      step={Step_Default_Value}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      Minutes Before Pushback Resubmitted for Processing
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      value={
                        pushbackDefaultData?.MessageRetryVisibilityTimeoutSeconds
                          ? Get_Minutes_Pushback_DefaultValue(
                              pushbackDefaultData?.MessageRetryVisibilityTimeoutSeconds
                            )
                          : Minutes_Min_Default_Value
                      }
                      onChange={(event, val) =>
                        updatePushbackData(
                          "MessageRetryVisibilityTimeoutSeconds",
                          Get_Seconds_Pushback_DefaultValue(val)
                        )
                      }
                      slots={{
                        root: StyledInputRoot,
                        input: StyledInputElement,
                        incrementButton: StyledButton,
                        decrementButton: StyledButton,
                      }}
                      slotProps={{
                        incrementButton: {
                          children: "▴",
                        },
                        decrementButton: {
                          children: "▾",
                        },
                      }}
                      min={Minutes_Min_Default_Value}
                      max={Minutes_Max_Default_Value}
                      step={Step_Default_Value}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      Minutes Before Locked Loan Pushback Resubmitted for
                      Processing
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      value={
                        pushbackDefaultData?.LoanLockedVisibilityTimeoutSeconds
                          ? Get_Minutes_Pushback_DefaultValue(
                              pushbackDefaultData?.LoanLockedVisibilityTimeoutSeconds
                            )
                          : Minutes_Min_Default_Value
                      }
                      onChange={(event, val) =>
                        updatePushbackData(
                          "LoanLockedVisibilityTimeoutSeconds",
                          Get_Seconds_Pushback_DefaultValue(val)
                        )
                      }
                      slots={{
                        root: StyledInputRoot,
                        input: StyledInputElement,
                        incrementButton: StyledButton,
                        decrementButton: StyledButton,
                      }}
                      slotProps={{
                        incrementButton: {
                          children: "▴",
                        },
                        decrementButton: {
                          children: "▾",
                        },
                      }}
                      min={Minutes_Min_Default_Value}
                      max={Minutes_Max_Default_Value}
                      step={Step_Default_Value}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Unnamed Document</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={pushbackDefaultData?.UnnamedDocumentTitle}
                      onChange={(e) =>
                        updatePushbackData(
                          "UnnamedDocumentTitle",
                          e.target.value
                        )
                      }
                      sx={{ flex: 1 }}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      Number Of Attempts Before Auto-Abandon
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      value={
                        pushbackDefaultData?.MaxMessageRetryCount
                          ? Get_Attempts_Pushback_DefaultValue(
                              pushbackDefaultData?.MaxMessageRetryCount
                            )
                          : Attempts_Min_Default_Value
                      }
                      onChange={(event, val) =>
                        updatePushbackData("MaxMessageRetryCount", val)
                      }
                      slots={{
                        root: StyledInputRoot,
                        input: StyledInputElement,
                        incrementButton: StyledButton,
                        decrementButton: StyledButton,
                      }}
                      slotProps={{
                        incrementButton: {
                          children: "▴",
                        },
                        decrementButton: {
                          children: "▾",
                        },
                      }}
                      min={Attempts_Min_Default_Value}
                      max={Attempts_Max_Default_Value}
                      step={Step_Default_Value}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={open}
              autoHideDuration={4000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity={alert.includes("Successful") ? "success" : "error"}
                variant="filled"
              >
                {alert}
              </Alert>
            </Snackbar>
          </CardContent>
        </Card>
        <ConfigBottomBar save={save} />
      </Box>
    </section>
  );
};

export default ConfigPushback;