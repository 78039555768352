import React, { useState } from "react";
import MuiAlert from '@mui/material/Alert';
import { Box, TextField, Button, SvgIcon, Snackbar } from "@mui/material";
import { FindReplace } from "@mui/icons-material";

interface AddDocumentMappingProps {
  open: boolean;
  addItemFunction: (
    docIndex: number,
    docName: string,
    encDocName: string
  ) => void;
  showSelectItem: () => void;
  encompassDocName: string;
}

const AddDocumentMapping: React.FC<AddDocumentMappingProps> = ({
  open,
  addItemFunction,
  showSelectItem,
  encompassDocName,
}) => {
  const [docIndex, setDocIndex] = useState(0);
  const [docName, setDocName] = useState("");

  // State Confirmations
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('info');

  const docIndexChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    var intVal = parseInt(value);
    if (intVal < -999999) {
      intVal = -999999;
    }
    if (intVal > 999999) {
      intVal = 999999;
    }
    setDocIndex(intVal);
  };

  const addItem = () => {
    if (docIndex === 0) {
      openAlert("Docutech Index must not equal 0.", 'error');
      return;
    }
    if (!docName || !docName.trim()) {
      openAlert("Docutech Name must have a value.", 'error');
      return;
    }
    addItemFunction(docIndex, docName, encompassDocName);
    setDocIndex(0);
    setDocName("");
  };

  // Alert Handling 
  const handleSnackBarClose = (_event: any) => { setAlertOpen(false); }
  const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(true);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        className={["flexLeft", "height100"].join(" ")}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 0.5,
          height: "100%",
        }}
      >
        <TextField
          label={"Docutech Index"}
          value={docIndex}
          onChange={docIndexChange}
          type="number"
          name="documentIndex"
          variant="outlined"
          size="small"
        />
      </Box>

      <Box
        className={["flexLeft", "height100"].join(" ")}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 0.5,
          height: "100%",
          flex: 1,
        }}
      >
        <TextField
          label={"Docutech Name"}
          value={docName}
          onChange={(e) => setDocName(e.target.value)}
          fullWidth
          name="docutechName"
          variant="outlined"
          size="small"
        />

        <TextField
          label={"Encompass Document Name"}
          value={encompassDocName}
          fullWidth
          name="docutechName"
          variant="outlined"
          size="small"
          inputProps={{ readOnly: true }}
        />

        <Button onClick={showSelectItem}>
          <SvgIcon sx={{ color: "#143A6B", cursor: "pointer" }} component={FindReplace} />
        </Button>
      </Box>

      <Button onClick={addItem}>Add</Button>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alertOpen}
        autoHideDuration={4000}
        onClose={handleSnackBarClose}
      >
        <MuiAlert
          onClose={handleSnackBarClose}
          severity={alertSeverity}
          sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default AddDocumentMapping;